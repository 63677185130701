import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
import { logout } from './userSlice';
import atsApi from '../../api/atsApi';

export const createTopic = createAsyncThunk(
	'topic/add_new',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.post('/topics', data);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getTopics = createAsyncThunk(
	'topic/get_main',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get('/topics');
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getSubtopics = createAsyncThunk(
	'topics/get_sub',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/topics/${data}/subtopics`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateTopic = createAsyncThunk(
	'topic/update_topic',
	async (data, { rejectWithValue }) => {
		const { id } = data;
		try {
			const res = await atsApi.put(`/topics/${id}`, data);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteTopic = createAsyncThunk(
	'topic/delete_topic',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.delete(`/topics/${data}`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const topicAdapter = createEntityAdapter();
const initialState = topicAdapter.getInitialState({
	loading: false,
	isCreateTopic: false,
	isSubtopic: false,
	newTopic: '',
	mainTopics: null,
	subtopics: null,
	selectedTopic: null,
	selectedSubtopic: null,
	success: null,
	errors: null,
});

export const topicSlice = createSlice({
	name: 'topic',
	initialState,
	reducers: {
		setIsCreateTopic: (state, action) => {
			state.isCreateTopic = action.payload;
		},
		setIsSubtopic: (state, action) => {
			state.isSubtopic = action.payload;
		},
		setNewTopic: (state, action) => {
			state.newTopic = action.payload;
		},
		setMainTopics: (state, action) => {
			state.mainTopics = action.payload;
		},
		setSubtopics: (state, action) => {
			state.subtopics = action.payload;
		},
		setSelectedTopic: (state, action) => {
			state.selectedTopic = action.payload;
		},
		setSelectedSubtopic: (state, action) => {
			state.selectedSubtopic = action.payload;
		},
		clearTopicSuccess: (state) => {
			state.success = null;
		},
		clearTopicErrors: (state) => {
			state.errors = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createTopic.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(createTopic.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.mainTopics = action.payload.mainTopics;
				state.subtopics = action.payload.subtopics;
				state.isCreateTopic = false;
				state.newTopic = '';
				state.isSubtopic = false;
			})
			.addCase(createTopic.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getTopics.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getTopics.fulfilled, (state, action) => {
				state.loading = false;
				state.mainTopics = action.payload;
			})
			.addCase(getTopics.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getSubtopics.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getSubtopics.fulfilled, (state, action) => {
				state.loading = false;
				state.subtopics = action.payload;
			})
			.addCase(getSubtopics.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(updateTopic.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(updateTopic.fulfilled, (state, action) => {
				state.loading = false;
				state.mainTopics = action.payload.mainTopics;
				state.subtopics = action.payload.subtopics;
			})
			.addCase(updateTopic.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(deleteTopic.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(deleteTopic.fulfilled, (state, action) => {
				state.loading = false;
				state.mainTopics = action.payload.mainTopics;
				state.subtopics = action.payload.subtopics;
				state.success = action.payload.success;
			})
			.addCase(deleteTopic.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(logout, (state) => {
				state.loading = false;
				state.isCreateTopic = false;
				state.newTopic = '';
				state.isSubtopic = false;
				state.mainTopics = null;
				state.subtopics = null;
				state.success = null;
				state.errors = null;
				topicAdapter.removeAll(state);
			});
	},
});

export const {
	setIsCreateTopic,
	setNewTopic,
	setIsSubtopic,
	setMainTopics,
	setSubtopics,
	setSelectedTopic,
	setSelectedSubtopic,
	clearTopicSuccess,
	clearTopicErrors,
} = topicSlice.actions;

export default topicSlice.reducer;
