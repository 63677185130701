import {
	Box,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	setTopicMenuOpen,
	setDrawerOpen,
} from '../../../../../../redux/slices/appSlice';
import {
	getTopics,
	getSubtopics,
	setMainTopics,
	setSubtopics,
} from '../../../../../../redux/slices/topicSlice';
import {
	setView,
	getPostsBySubtopic,
	setAllPosts,
	setSelectedPost,
	clearPostData,
} from '../../../../../../redux/slices/postSlice';
import RouterLink from '../../../../../../components/RouterLink';

const NavItem = ({ data }) => {
	const { isMobile, topicMenuOpen } = useSelector((state) => state.app);
	const { mainTopics, subtopics } = useSelector((state) => state.topic);
	const { selectedPost } = useSelector((state) => state.post);
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const active = data.path === pathname;

	const handleClick = () => {
		if (isMobile) {
			dispatch(setDrawerOpen(false));
		}

		if (data.title === 'blog') {
			dispatch(setView('topics'));
			dispatch(getTopics());
			navigate('/admin/blog');
		} else {
			dispatch(setMainTopics(null));
			dispatch(setSubtopics(null));
		}
		dispatch(setAllPosts(null));
		dispatch(setSelectedPost(null));
	};

	const handleMainClick = (topicId) => {
		if (subtopics) {
			dispatch(setTopicMenuOpen(false));
			dispatch(setSubtopics(null));
			dispatch(setAllPosts(null));
			dispatch(setView('topics'));
		} else {
			dispatch(getSubtopics(topicId));
			dispatch(setTopicMenuOpen(true));
			dispatch(setView('subtopics'));
		}

		if (selectedPost) {
			dispatch(setSelectedPost(null));
			dispatch(clearPostData());
		}
	};

	const handleSubClick = (topic, subtopic) => {
		dispatch(getPostsBySubtopic({ topic, subtopic }));
		dispatch(setView('posts'));

		if (selectedPost) {
			dispatch(setSelectedPost(null));
			dispatch(clearPostData());
		}
	};

	const buttonStyles = {
		minHeight: 44,
		borderRadius: 0.75,
		typography: 'body2',
		color: 'text.secondary',
		textTransform: 'capitalize',
		fontWeight: 'fontWeightMedium',
		...(active && {
			color: 'primary.main',
			fontWeight: 'fontWeightSemiBold',
			bgcolor: 'rgba(23, 118, 242, .08)',
			'&:hover': {
				bgcolor: 'rgba(23, 118, 242, .16)',
			},
		}),
	};

	const renderTopics = () =>
		mainTopics?.map((mainItem) => (
			<div key={mainItem._id}>
				<ListItemButton
					sx={{ pl: isMobile ? 5 : 8 }}
					component={RouterLink}
					href={subtopics ? `/admin/blog` : `/admin/blog/${mainItem.topic}`}
					onClick={() => handleMainClick(mainItem._id)}
				>
					<ListItemText primary={mainItem.topic} />
				</ListItemButton>
				<Collapse in={topicMenuOpen} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{subtopics?.map(
							(item) =>
								item.mainTopic._id === mainItem._id && (
									<ListItemButton
										key={item._id}
										sx={{ pl: isMobile ? 7 : 10 }}
										component={RouterLink}
										href={`/admin/blog/${item?.mainTopic?.topic}/${item.topic}/posts`}
										onClick={() =>
											handleSubClick(item.mainTopic.topic, item.topic)
										}
									>
										<ListItemText primary={item.topic} />
									</ListItemButton>
								)
						)}
					</List>
				</Collapse>
			</div>
		));

	return (
		<>
			<ListItemButton
				component={RouterLink}
				href={data.path}
				onClick={handleClick}
				sx={buttonStyles}
			>
				<Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
					{data.icon}
				</Box>
				<Box component='span'>{data.title}</Box>
			</ListItemButton>
			{data.title === 'blog' && renderTopics()}
		</>
	);
};

export default NavItem;
