export const radioValueHandler = (value) => value === 'true';

export const visuallyHidden = {
	border: 0,
	margin: -1,
	padding: 0,
	width: '1px',
	height: '1px',
	overflow: 'hidden',
	position: 'absolute',
	whiteSpace: 'nowrap',
	clip: 'rect(0 0 0 0)',
};

export function emptyRows(page, rowsPerPage, arrayLength) {
	return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

function descendingComparator(a, b, orderBy) {
	if (a[orderBy] === null) {
		return 1;
	}
	if (b[orderBy] === null) {
		return -1;
	}
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
export function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

export function applyFilter({ inputData, comparator, filterName }) {
	const stabilizedThis = inputData.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	inputData = stabilizedThis.map((el) => el[0]);

	if (filterName) {
		inputData = inputData.filter(
			(user) =>
				user.displayName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
		);
	}

	return inputData;
}

export const userRows = (data, user) => {
	let options = [];

	const createData = (
		_id,
		displayName,
		firstName,
		lastName,
		avatarUrl,
		email,
		username,
		createdAt,
		optInEmail,
		optInSms,
		optInVoice
	) => {
		return {
			_id,
			displayName,
			firstName,
			lastName,
			avatarUrl,
			email,
			username,
			createdAt,
			optInEmail,
			optInSms,
			optInVoice,
		};
	};

	data = data?.filter((item) => item._id !== user._id);

	data?.forEach((item) => {
		options.push(
			createData(
				item._id,
				`${item.lastName}, ${item.firstName}`,
				item.firstName,
				item.lastName,
				item.profilePic,
				item.email,
				item.username,
				item.createdAt,
				item.communication.email,
				item.communication.sms,
				item.communication.voice
			)
		);
	});

	return options;
};

export const not = (a, b) => {
	return a.filter((value) => b.indexOf(value) === -1);
};

export const intersection = (a, b) => {
	return a.filter((value) => b.indexOf(value) !== -1);
};

export const capitalizeFirstLetterOfEachWord = (string) => {
	return string
		?.split(' ')
		.map((word) => {
			return word.charAt(0).toUpperCase() + word.slice(1);
		})
		.join(' ');
};

export const compareArray = (arr1, arr2) => {
	const matches = arr1.filter((obj1) =>
		arr2.every((obj2) => obj1._id === obj2._id && obj1.tag === obj2.tag)
	);

	return matches.length === arr1.length ? true : false;
};
