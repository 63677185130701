import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
import { openDelete } from './appSlice';
import { logout } from './userSlice';
import atsApi from '../../api/atsApi';

export const getAvailableTopics = createAsyncThunk(
	'post/get_avail_topics',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get('/topics');
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getAvailableSubs = createAsyncThunk(
	'post/get_avail_subs',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/topics/${data}/subtopics`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createPost = createAsyncThunk(
	'post/create_post',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.post('/posts', data);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getAllPosts = createAsyncThunk(
	'post/get_all_posts',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get('/posts');
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getPostsByTopic = createAsyncThunk(
	'post/get_topic',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/posts/?topic=${data}`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getPostsBySubtopic = createAsyncThunk(
	'post/get_subtopic',
	async (data, { rejectWithValue }) => {
		const { topic, subtopic } = data;

		try {
			const res = await atsApi.get(
				`/posts/?topic=${topic}&subtopic=${subtopic}`
			);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getPostsByTag = createAsyncThunk(
	'post/get_tag',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/posts/?tag=${data}`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getPostById = createAsyncThunk(
	'post/get_id',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/posts/?id=${data}`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updatePost = createAsyncThunk(
	'post/update_post',
	async (data, { rejectWithValue, dispatch }) => {
		const { id, ...others } = data;
		try {
			const res = await atsApi.put(`/posts/${id}`, others);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateViews = createAsyncThunk(
	'post/update_views',
	async (data, { rejectWithValue, dispatch }) => {
		const { id, topic, subtopic } = data;
		try {
			const res = await atsApi.put(`/posts/${id}/views`);
			const { success } = res.data;
			if (success) dispatch(getPostsBySubtopic({ topic, subtopic }));
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const managePostMedia = createAsyncThunk(
	'post/manage_media',
	async (data, { rejectWithValue, dispatch }) => {
		const postId = data.get('postId');
		try {
			const res = await atsApi.put(`/posts/${postId}/media`, data);
			const { success } = res.data;
			if (success) {
				dispatch(openDelete(false));
			}
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deletePost = createAsyncThunk(
	'post/delete_post',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const res = await atsApi.delete(`/posts/${data}`);
			const { success } = res.data;
			if (success) {
				dispatch(getAllPosts());
				dispatch(openDelete(false));
			}
			return success;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const postAdapter = createEntityAdapter();
const initialState = postAdapter.getInitialState({
	loading: false,
	view: 'topics',
	availableTopics: null,
	availableSubtopics: null,
	topic: '',
	subtopic: '',
	title: '',
	tags: [],
	content: '',
	showCarousel: false,
	carouselSpeed: 500,
	media: null,
	mediaPreview: null,
	isCover: false,
	videoAddress: '',
	allPosts: null,
	selectedPost: null,
	success: null,
	errors: null,
});

export const postSlice = createSlice({
	name: 'post',
	initialState,
	reducers: {
		setView: (state, action) => {
			state.view = action.payload;
		},
		setAvailableTopics: (state, action) => {
			state.availableTopics = action.payload;
		},
		setAvailableSubs: (state, action) => {
			state.availableSubtopics = action.payload;
		},
		setTopic: (state, action) => {
			state.topic = action.payload;
		},
		setSubtopic: (state, action) => {
			state.subtopic = action.payload;
		},
		setTitle: (state, action) => {
			state.title = action.payload;
		},
		setTags: (state, action) => {
			state.tags = action.payload;
		},
		setContent: (state, action) => {
			state.content = action.payload;
		},
		setShowCarousel: (state, action) => {
			state.showCarousel = action.payload;
		},
		setCarouselSpeed: (state, action) => {
			state.carouselSpeed = parseInt(action.payload);
		},
		setMedia: (state, action) => {
			state.media = action.payload;
		},
		setMediaPreview: (state, action) => {
			state.mediaPreview = action.payload;
		},
		setIsCover: (state, action) => {
			state.isCover = action.payload;
		},
		setVideoAddress: (state, action) => {
			state.videoAddress = action.payload;
		},
		setAllPosts: (state, action) => {
			state.allPosts = action.payload;
		},
		setSelectedPost: (state, action) => {
			state.selectedPost = action.payload;
		},
		populatePostData: (state, action) => {
			state.topic = action.payload.topic;
			state.subtopic = action.payload.subtopic;
			state.title = action.payload.title;
			state.tags = action.payload.tags;
			state.content = action.payload.content;
			state.showCarousel = action.payload.showCarousel;
			state.carouselSpeed = action.payload.carouselSpeed;
			state.media = action.payload.media;
			state.videoAddress = action.payload.videoAddress;
		},
		clearPostData: (state) => {
			state.topic = '';
			state.subtopic = '';
			state.title = '';
			state.tags = [];
			state.content = '';
			state.showCarousel = false;
			state.carouselSpeed = 500;
			state.media = null;
			state.videoAddress = '';
		},
		clearSelectedPost: (state) => {
			state.selectedPost = null;
		},
		clearSuccess: (state) => {
			state.success = null;
		},
		clearErrors: (state) => {
			state.errors = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAvailableTopics.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getAvailableTopics.fulfilled, (state, action) => {
				state.loading = false;
				state.availableTopics = action.payload;
			})
			.addCase(getAvailableTopics.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getAvailableSubs.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getAvailableSubs.fulfilled, (state, action) => {
				state.loading = false;
				state.availableSubtopics = action.payload;
			})
			.addCase(getAvailableSubs.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(createPost.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(createPost.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.allPosts = action.payload.posts;
				state.selectedPost = action.payload.post;
				state.availableTopics = null;
				state.availableSubtopics = null;
				state.topic = '';
				state.subtopic = '';
				state.title = '';
				state.content = '';
				state.media = null;
				state.videoAddress = '';
				state.tags = [];
			})
			.addCase(createPost.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getAllPosts.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getAllPosts.fulfilled, (state, action) => {
				state.loading = false;
				state.allPosts = action.payload;
			})
			.addCase(getAllPosts.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getPostsByTopic.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getPostsByTopic.fulfilled, (state, action) => {
				state.loading = false;
				state.allPosts = action.payload;
			})
			.addCase(getPostsByTopic.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getPostsBySubtopic.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getPostsBySubtopic.fulfilled, (state, action) => {
				state.loading = false;
				state.allPosts = action.payload;
			})
			.addCase(getPostsBySubtopic.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getPostsByTag.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getPostsByTag.fulfilled, (state, action) => {
				state.loading = false;
				state.allPosts = action.payload;
			})
			.addCase(getPostsByTag.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getPostById.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getPostById.fulfilled, (state, action) => {
				state.loading = false;
				state.selectedPost = action.payload;
			})
			.addCase(getPostById.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(updatePost.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(updatePost.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.selectedPost = action.payload.updated;
				state.availableTopics = null;
				state.availableSubtopics = null;
				state.topic = '';
				state.subtopic = '';
				state.title = '';
				state.tags = [];
				state.content = '';
				state.showCarousel = false;
				state.carouselSpeed = 500;
			})
			.addCase(updatePost.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(updateViews.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(updateViews.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.selectedPost = action.payload.updated;
			})
			.addCase(updateViews.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(managePostMedia.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(managePostMedia.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.selectedPost = action.payload.updated;
				state.allPosts = action.payload.allPosts;
				state.mediaPreview = null;
				state.isCover = false;
			})
			.addCase(managePostMedia.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(deletePost.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(deletePost.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload;
				state.selectedPost = null;
			})
			.addCase(deletePost.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(logout, (state) => {
				state.loading = false;
				state.view = 'topics';
				state.availableTopics = null;
				state.availableSubtopics = null;
				state.topic = '';
				state.subtopic = '';
				state.title = '';
				state.content = '';
				state.showCarousel = false;
				state.carouselSpeed = 500;
				state.media = null;
				state.mediaPreview = null;
				state.isCover = false;
				state.videoAddress = '';
				state.tags = [];
				state.allPosts = null;
				state.selectedPost = null;
				state.success = null;
				state.errors = null;
				postAdapter.removeAll(state);
			});
	},
});

export const {
	setView,
	setAvailableSubs,
	setAvailableTopics,
	setTopic,
	setSubtopic,
	setTitle,
	setContent,
	setShowCarousel,
	setCarouselSpeed,
	setMedia,
	setMediaPreview,
	setIsCover,
	setVideoAddress,
	setAllPosts,
	setTags,
	setSelectedPost,
	populatePostData,
	clearPostData,
	clearSelectedPost,
	clearSuccess,
	clearErrors,
} = postSlice.actions;

export default postSlice.reducer;
