import {
	Button,
	Checkbox,
	Grid,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllTags } from '../../redux/slices/tagSlice';
import { setTags } from '../../redux/slices/postSlice';
import { not, intersection } from '../../util/helpers';
import './transfer.scss';

const TransferList = ({ isUpdate }) => {
	const { activeUser } = useSelector((state) => state.user);
	let { allTags } = useSelector((state) => state.tag);
	const { tags } = useSelector((state) => state.post);
	const [checked, setChecked] = useState([]);
	const dispatch = useDispatch();
	const theme = activeUser?.theme;

	if (isUpdate) {
		allTags = allTags.filter(
			(item) => !tags.some((tag) => tag._id === item._id)
		);
	}

	const leftChecked = intersection(checked, allTags);
	const rightChecked = intersection(checked, tags);

	const handleToggle = (item) => () => {
		const currentIndex = checked.indexOf(item);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		dispatch(setTags(tags.concat(allTags)));
		dispatch(setAllTags([]));
	};

	const handleCheckedRight = () => {
		dispatch(setTags(tags.concat(leftChecked)));
		dispatch(setAllTags(not(allTags, leftChecked)));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		dispatch(setAllTags(allTags.concat(rightChecked)));
		dispatch(setTags(not(tags, rightChecked)));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		dispatch(setAllTags(allTags.concat(tags)));
		dispatch(setTags([]));
	};

	const customList = (items) => (
		<Paper
			className={theme === 'dark' ? 'transfer-paper dark' : 'transfer-paper'}
		>
			<List dense component='div' role='list'>
				{items?.map((item) => {
					const labelId = `transfer-list-item-${item.tag}-label`;

					return (
						<ListItemButton
							key={item._id}
							role='listitem'
							onClick={handleToggle(item)}
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(item) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={item.tag} />
						</ListItemButton>
					);
				})}
			</List>
		</Paper>
	);

	return (
		<Grid
			container
			spacing={2}
			justifyContent='center'
			alignItems='center'
			id='transfer-list'
		>
			<Grid item>{customList(allTags)}</Grid>
			<Grid item>
				<Grid container direction='column' alignItems='center'>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleAllRight}
						disabled={allTags?.length === 0}
						aria-label='move all right'
					>
						≫
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleCheckedRight}
						disabled={leftChecked?.length === 0}
						aria-label='move selected right'
					>
						&gt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleCheckedLeft}
						disabled={rightChecked?.length === 0}
						aria-label='move selected left'
					>
						&lt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleAllLeft}
						disabled={tags?.length === 0}
						aria-label='move all left'
					>
						≪
					</Button>
				</Grid>
			</Grid>
			<Grid item>{customList(tags)}</Grid>
		</Grid>
	);
};

export default TransferList;
