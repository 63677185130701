import { Card, CardContent } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	register,
	userLogin,
	setAuthType,
	setLogin,
	setFirstName,
	setLastName,
	setUsername,
	setEmail,
	setPassword,
	setConfirmPassword,
	clearErrors,
} from '../../redux/slices/userSlice';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import './auth.scss';
import TouchableOpacity from '../../components/TouchableOpacity';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';

const Auth = () => {
	const {
		loading,
		authType,
		login,
		firstName,
		lastName,
		username,
		email,
		password,
		confirmPassword,
		phone,
		activeUser,
		errors,
	} = useSelector((state) => state.user);
	const formatted = authType.charAt(0).toUpperCase() + authType.slice(1);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleFocus = () => {
		dispatch(clearErrors());
	};

	const handleChange = (input, value) => {
		const actionMap = {
			toggle: setAuthType,
			login: setLogin,
			first: setFirstName,
			last: setLastName,
			username: setUsername,
			email: setEmail,
			password: setPassword,
			confirm: setConfirmPassword,
		};

		const action = actionMap[input];

		if (action) {
			dispatch(action(value));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let data;

		if (authType === 'login') {
			data = {
				login,
				password,
			};
			dispatch(userLogin(data));
		} else if (authType === 'register') {
			data = {
				firstName,
				lastName,
				username,
				email,
				password,
				confirmPassword,
				phone,
			};
			dispatch(register(data));
		}
	};

	const loginSuccess = useCallback(() => {
		if (activeUser?.role === 'user') {
			navigate('/member/home');
		} else if (
			activeUser?.role === 'admin' ||
			activeUser?.role === 'superAdmin'
		) {
			navigate('/admin/dashboard');
		}
	}, [activeUser, navigate]);

	useEffect(() => {
		loginSuccess();
	}, [loginSuccess]);

	return (
		<div id='auth'>
			<Card id='auth-card' elevation={12}>
				<CardContent className='container'>
					<TouchableOpacity
						onClick={() =>
							handleChange(
								'toggle',
								authType === 'login' ? 'register' : 'login'
							)
						}
					>
						<h2 className='auth-type'>{formatted}</h2>
						{authType === 'login' ? (
							<LoginIcon className='icon' fontSize='large' />
						) : (
							<PersonAddIcon className='icon' fontSize='large' />
						)}
					</TouchableOpacity>
					<form onSubmit={handleSubmit}>
						{authType === 'register' && (
							<>
								<TextInput
									placeholder='First Name'
									value={firstName}
									onFocus={handleFocus}
									onChange={(e) => handleChange('first', e.target.value)}
									error={errors?.firstName}
								/>
								<TextInput
									placeholder='Last Name'
									value={lastName}
									onFocus={handleFocus}
									onChange={(e) => handleChange('last', e.target.value)}
									error={errors?.lastName}
								/>
								<TextInput
									autoCapitalize='none'
									placeholder='Username'
									value={username}
									onFocus={handleFocus}
									onChange={(e) => handleChange('username', e.target.value)}
									error={errors?.username}
								/>
								<TextInput
									type='email'
									placeholder='Email'
									value={email}
									onFocus={handleFocus}
									onChange={(e) => handleChange('email', e.target.value)}
									error={errors?.email}
								/>
							</>
						)}
						{authType === 'login' && (
							<TextInput
								type='text'
								autoCapitalize='none'
								placeholder='Username or Email'
								value={login}
								onFocus={handleFocus}
								onChange={(e) => handleChange('login', e.target.value)}
								error={errors?.login}
							/>
						)}
						<TextInput
							type='password'
							placeholder='Password'
							value={password}
							onFocus={handleFocus}
							onChange={(e) => handleChange('password', e.target.value)}
							error={errors?.password}
						/>
						{authType === 'register' && (
							<TextInput
								type='password'
								placeholder='Confirm Password'
								value={confirmPassword}
								onFocus={handleFocus}
								onChange={(e) => handleChange('confirm', e.target.value)}
								error={errors?.confirm}
							/>
						)}
						{errors?.message && <h6 className='error'>{errors?.message}</h6>}
						<Button
							type='submit'
							loading={loading}
							label='SUBMIT'
							btnClass='auth-btn'
						/>
					</form>
					{/* {authType === 'login' && (
						<Link className='link' to='/forgot-password'>
							Forgot Password
						</Link>
					)} */}
				</CardContent>
			</Card>
		</div>
	);
};

export default Auth;
