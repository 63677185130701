import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	setNewTopic,
	setIsSubtopic,
	setSelectedTopic,
	createTopic,
	setIsCreateTopic,
} from '../../redux/slices/topicSlice';
import {
	createTag,
	setIsCreateTag,
	setNewTag,
} from '../../redux/slices/tagSlice';
import Select from '../Select';
import TextInput from '../TextInput';

const CreateDialog = ({ open, type }) => {
	const { mainTopics, isSubtopic, selectedTopic, newTopic } = useSelector(
		(state) => state.topic
	);
	const { newTag } = useSelector((state) => state.tag);
	const dispatch = useDispatch();

	const handleChange = (input, value) => {
		const actionMap = {
			topic: setNewTopic,
			isSub: setIsSubtopic,
			main: setSelectedTopic,
			tag: setNewTag,
		};

		const action = actionMap[input];

		action &&
			dispatch(
				action(input === 'main' && value !== null ? mainTopics[value] : value)
			);
	};

	const handleCreate = (e) => {
		e.preventDefault();

		const data = {
			...(type.toLowerCase() === 'topic' && { topic: newTopic }),
			...(isSubtopic && { isSubtopic }),
			...(isSubtopic && { mainTopic: selectedTopic?._id }),
			...(type.toLowerCase() === 'tag' && { tag: newTag }),
		};

		const actionMap = {
			topic: createTopic,
			tag: createTag,
		};

		const action = actionMap[type.toLowerCase()];

		action && dispatch(action(data));
	};

	const handleClose = () => {
		if (type === 'Topic') {
			dispatch(setIsCreateTopic(false));
			dispatch(setIsSubtopic(false));
		} else if (type === 'Tag') {
			dispatch(setIsCreateTag(false));
		}
	};

	return (
		<Dialog
			open={open}
			PaperProps={{ component: 'form', onSubmit: handleCreate }}
			maxWidth='xs'
			fullWidth
		>
			<DialogTitle>Create {type}</DialogTitle>
			<DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
				{isSubtopic && (
					<Select
						label='Choose Main Topic'
						onChange={(e) => handleChange('main', e.target.value)}
					>
						<option value={null}></option>
						{mainTopics?.map((item, index) => (
							<option key={item._id} value={index}>
								{item.topic}
							</option>
						))}
					</Select>
				)}
				<Stack direction='row' alignItems='center' gap={5}>
					<TextInput
						placeholder={`New ${type}`}
						value={
							type.toLowerCase() === 'topic'
								? newTopic
								: type.toLowerCase() === 'tag' && newTag
						}
						onChange={(e) =>
							handleChange(`${type.toLowerCase()}`, e.target.value)
						}
					/>
					{type.toLowerCase() === 'topic' && (
						<FormControlLabel
							control={
								<Checkbox
									checked={isSubtopic}
									onChange={(e) => handleChange('isSub', e.target.checked)}
								/>
							}
							label='Subtopic?'
						/>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				{type === 'Topic' && (
					<Button
						disabled={!newTopic || (isSubtopic && !selectedTopic)}
						type='submit'
					>
						Create
					</Button>
				)}
				{type === 'Tag' && (
					<Button disabled={!newTag} type='submit'>
						Create
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default CreateDialog;
