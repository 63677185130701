import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setDrawerOpen } from '../../../../redux/slices/appSlice';
import {
	setMainTopics,
	setSubtopics,
} from '../../../../redux/slices/topicSlice';
import { NAV, HEADER } from '../../../../util/data';
import Button from '../../../../components/Button';

const Header = () => {
	const { isMobile } = useSelector((state) => state.app);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleDrawer = () => {
		dispatch(setDrawerOpen(true));
	};

	const handleLoginClick = () => {
		navigate('/login');
		dispatch(setMainTopics(null));
		dispatch(setSubtopics(null));
	};

	const appBarStyles = {
		boxShadow: 'none',
		height: HEADER.H_MOBILE,
		zIndex: 1101,
		bgcolor: '#f7f7f7',
		...(!isMobile && {
			width: `calc(100% - ${NAV.WIDTH + 1}px)`,
			height: HEADER.H_DESKTOP,
		}),
	};

	const toolbarStyles = {
		height: 1,
		px: { lg: 5 },
	};

	const btnStyles = {
		marginLeft: isMobile ? '-40px' : '-173.4px',
	};

	return (
		<AppBar sx={appBarStyles}>
			<Toolbar sx={toolbarStyles}>
				{isMobile && (
					<IconButton sx={{ mr: 1 }} onClick={handleDrawer}>
						<MenuUnfoldOutlined />
					</IconButton>
				)}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						flexGrow: 1,
					}}
				>
					<Button
						label='Member Login'
						btnClass='lnk-btn'
						btnStyle={btnStyles}
						onClick={handleLoginClick}
					/>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
