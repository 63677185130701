import { Card, CardActions, CardMedia } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setTopicMenuOpen } from '../../../../redux/slices/appSlice';
import { getSubtopics } from '../../../../redux/slices/topicSlice';
import {
	getPostsBySubtopic,
	setView,
} from '../../../../redux/slices/postSlice';
import Button from '../../../../components/Button';

const TopicItem = ({ data }) => {
	const { activeUser } = useSelector((state) => state.user);
	const { _id, topic, topicImage, isSubtopic, mainTopic } = data;
	const theme = activeUser?.theme;
	const dispatch = useDispatch();

	const handleClick = () => {
		if (!isSubtopic) {
			dispatch(getSubtopics(_id));
			dispatch(setTopicMenuOpen(true));
			dispatch(setView('subtopics'));
			return;
		}

		const postData = { topic: mainTopic.topic, subtopic: topic };
		dispatch(getPostsBySubtopic(postData));
		dispatch(setView('posts'));
	};

	const cardStyles = {
		width: 345,
		height: 'fit-content',
		borderRadius: 4,
	};

	const mediaStyles = {
		height: 195,
		borderRadius: 4,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	};

	return (
		<Card
			sx={cardStyles}
			className={theme === 'dark' ? theme : ''}
			elevation={10}
		>
			<CardMedia
				sx={mediaStyles}
				component='img'
				src={topicImage}
				title='topic image'
			/>
			<CardActions>
				<Button label={topic} btnClass='main-topic-btn' onClick={handleClick} />
			</CardActions>
		</Card>
	);
};

export default TopicItem;
