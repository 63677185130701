import { configureStore } from '@reduxjs/toolkit';
import {
	persistReducer,
	persistStore,
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from './slices/appSlice';
import userReducer from './slices/userSlice';
import contentReducer from './slices/contentSlice';
import postReducer from './slices/postSlice';
import topicReducer from './slices/topicSlice';
import tagReducer from './slices/tagSlice';

const userPersistConfig = {
	key: 'user',
	storage,
	whitelist: ['activeUser'],
};

const contentPersistConfig = {
	key: 'content',
	storage,
	whitelist: ['featuredContent', 'singleContent'],
};

const postPersistConfig = {
	key: 'post',
	storage,
	whitelist: ['selectedPost'],
};

const topicPersistConfig = {
	key: 'topic',
	storage,
	whitelist: ['mainTopics', 'subTopics'],
};

const tagPersistConfig = {
	key: 'tag',
	storage,
	whitelist: ['allTags'],
};

export const store = configureStore({
	reducer: {
		app: appReducer,
		user: persistReducer(userPersistConfig, userReducer),
		content: persistReducer(contentPersistConfig, contentReducer),
		post: persistReducer(postPersistConfig, postReducer),
		topic: persistReducer(topicPersistConfig, topicReducer),
		tag: persistReducer(tagPersistConfig, tagReducer),
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoreActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
			},
		}),
});

export const persistor = persistStore(store);
