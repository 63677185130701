import {
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	FormControlLabel,
	Paper,
	Stack,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsCreateTopic } from '../../redux/slices/topicSlice';
import { getAllTags, setIsCreateTag } from '../../redux/slices/tagSlice';
import {
	getAvailableTopics,
	getAvailableSubs,
	setAvailableSubs,
	setTopic,
	setSubtopic,
	setTitle,
	setContent,
	setVideoAddress,
	createPost,
	clearSuccess,
	clearErrors,
} from '../../redux/slices/postSlice';
import './create-post.scss';
import Section from '../../components/Section';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';
import TransferList from '../../components/TransferList';
import CreateDialog from '../../components/CreateDialog';

const CreatePost = () => {
	const { isMobile } = useSelector((state) => state.app);
	const { activeUser } = useSelector((state) => state.user);
	const { isCreateTopic } = useSelector((state) => state.topic);
	const { isCreateTag } = useSelector((state) => state.tag);
	const {
		availableTopics,
		availableSubtopics,
		topic,
		subtopic,
		title,
		tags,
		content,
		videoAddress,
		selectedPost,
		success,
		errors,
	} = useSelector((state) => state.post);
	const [createType, setCreateType] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleFocus = () => {
		dispatch(clearErrors());
	};

	const handleChange = (input, value) => {
		switch (input) {
			case 'createTopic':
				dispatch(setIsCreateTopic(value));
				setCreateType('Topic');
				break;
			case 'topic':
				dispatch(setTopic(value === '-1' ? '' : availableTopics[value].topic));
				value === '-1'
					? dispatch(setAvailableSubs(null))
					: dispatch(getAvailableSubs(availableTopics[value]?._id));
				break;
			case 'subtopic':
				dispatch(
					setSubtopic(value === '-1' ? '' : availableSubtopics[value].topic)
				);
				break;
			case 'title':
				dispatch(setTitle(value));
				break;
			case 'createCat':
				dispatch(setIsCreateTag(value));
				setCreateType('Tag');
				break;
			case 'video':
				dispatch(setVideoAddress(value));
				break;
			case 'content':
				dispatch(setContent(value));
				break;
			default:
				break;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const newPost = {
			topic,
			subtopic,
			title,
			tags,
			content,
			...(videoAddress && { videoAddress }),
			author: activeUser?._id,
		};

		dispatch(createPost(newPost));
	};

	const handleLoadTopics = useCallback(() => {
		dispatch(getAvailableTopics());
	}, [dispatch]);

	const handleLoadTags = useCallback(() => {
		dispatch(getAllTags());
	}, [dispatch]);

	const handleSuccess = useCallback(() => {
		if (success) navigate(`/admin/blog/post/${selectedPost?._id}`);
		dispatch(clearSuccess());
	}, [navigate, dispatch, success, selectedPost]);

	useEffect(() => {
		handleLoadTopics();
		handleLoadTags();
	}, [handleLoadTopics, handleLoadTags]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<Section id='create-post' maxWidth='xl'>
			<Paper elevation={5}>
				<h2 className='page-title'>Create Post</h2>
				<form className='post-porm' onSubmit={handleSubmit}>
					<Divider>
						<Chip label='Topic' size='small' className='divider-chip' />
					</Divider>
					<div className='create-form-group topic'>
						<Stack
							width={isMobile ? undefined : '350px'}
							direction={isMobile ? 'column' : 'row'}
							justifyContent='center'
							alignItems='center'
						>
							<Select
								onChange={(e) => handleChange('topic', e.target.value)}
								onFocus={handleFocus}
								error={errors?.topic}
							>
								<option value='-1'>Topic</option>
								{availableTopics?.map((item, index) => (
									<option key={item._id} value={index}>
										{item.topic}
									</option>
								))}
							</Select>
							<Select
								onChange={(e) => handleChange('subtopic', e.target.value)}
								onFocus={handleFocus}
								error={errors?.subtopic}
							>
								<option value='-1'>Subtopic</option>
								{availableSubtopics?.map((item, index) => (
									<option key={item._id} value={index}>
										{item.topic}
									</option>
								))}
							</Select>
						</Stack>
						<FormControlLabel
							control={
								<Checkbox
									checked={isCreateTopic}
									onChange={(e) =>
										handleChange('createTopic', e.target.checked)
									}
								/>
							}
							label='New Topic'
						/>
					</div>
					<Divider>
						<Chip label='Title' size='small' className='divider-chip' />
					</Divider>
					<div className='create-form-group title'>
						<FormControl>
							<TextInput
								placeholder='Title'
								value={title}
								onChange={(e) => handleChange('title', e.target.value)}
								onFocus={handleFocus}
								error={errors?.title}
							/>
						</FormControl>
					</div>
					<Divider>
						<Chip label='Tags' size='small' className='divider-chip' />
					</Divider>
					<div className='create-form-group transfer'>
						<FormControl>
							<TransferList />
						</FormControl>
						<FormControlLabel
							control={
								<Checkbox
									checked={isCreateTag}
									onChange={(e) => handleChange('createCat', e.target.checked)}
								/>
							}
							label='New Tag'
						/>
					</div>
					<Divider>
						<Chip label='Video' size='small' className='divider-chip' />
					</Divider>
					{videoAddress && (
						<iframe
							title='Vlog Post'
							src={videoAddress}
							frameBorder='0'
							controls='0'
							allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
							className='create-img'
						/>
					)}
					<div className='create-form-group video-url'>
						<FormControl>
							<TextInput
								placeholder='Video URL'
								value={videoAddress}
								onChange={(e) => handleChange('video', e.target.value)}
							/>
						</FormControl>
					</div>
					<Divider>
						<Chip label='Content' size='small' className='divider-chip' />
					</Divider>
					<div className='create-form-group'>
						<FormControl fullWidth>
							<textarea
								cols='50'
								rows='10'
								placeholder='Tell your story...'
								className='create-input create-text'
								value={content}
								onChange={(e) => handleChange('content', e.target.value)}
							/>
						</FormControl>
					</div>
					<Button type='submit' variant='contained' className='create-submit'>
						Publish
					</Button>
				</form>
			</Paper>
			<CreateDialog open={isCreateTopic || isCreateTag} type={createType} />
		</Section>
	);
};

export default CreatePost;
