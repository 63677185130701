// import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { socket } from './util/socket';
import './app.scss';
import ComingSoon from './features/ComingSoon';
import Home from './features/Home';
import Auth from './features/Auth';
import ForgotPassword from './features/ForgotPassword';
import ResetPassword from './features/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import AdminLayout from './layouts/AdminLayout';
import MemberLayout from './layouts/MemberLayout';
import NonUserLayout from './layouts/NonUserLayout';
import AdminDashbord from './features/Admin/screens/Dashboard';
import Users from './features/Admin/screens/Users';
import Catalog from './features/Catalog';
import Preferences from './features/Member/screens/Preferences';
import MemberHome from './features/Member/screens/Home';
import AboutMe from './features/Member/screens/AboutMe';
import Watch from './features/Member/screens/Watch';
import Profile from './features/Profile/screens/Profile';
import BusinessCard from './features/BusinessCard';
import CreatePost from './features/CreatePost';
import Post from './features/Post';
import Blog from './features/Blog';

// import Sandbox from './features/Sandbox';

function App() {
	const { activeUser } = useSelector((state) => state.user);
	const { view } = useSelector((state) => state.post);
	const theme = activeUser?.theme;
	// let setupInterval;

	// const handleSetup = useCallback(() => {
	// 	if (activeUser !== null) {
	// 		setupInterval = setInterval(() => {
	// 			socket.emit('setup', activeUser);
	// 		}, 45000);
	// 	} else {
	// 		if (setupInterval) {
	// 			clearInterval(setupInterval);
	// 			setupInterval = null;
	// 		}
	// 		socket.emit('disconnected');
	// 	}
	// }, [activeUser, socket]);

	// useEffect(() => {
	// 	handleSetup();
	// 	return () => {
	// 		// Clear interval on unmount or dependency change
	// 		if (setupInterval) {
	// 			clearInterval(setupInterval);
	// 			setupInterval = null; // Reset the setupInterval variable
	// 		}
	// 	};
	// }, [handleSetup]);

	return (
		<div className='app' data-theme={theme}>
			<Router>
				<Routes>
					<Route path='/' element={<ComingSoon />} />
					<Route path='/dev-home' element={<Home />} />
					<Route path='/login' element={<Auth />} />
					<Route path='/forgot-password' element={<ForgotPassword />} />
					<Route path='/reset-password/:id' element={<ResetPassword />} />
					<Route
						path='/about-me'
						element={<MemberLayout children={<AboutMe />} />}
					/>
					<Route
						path='/admin/dashboard'
						element={
							<ProtectedRoute
								element={<AdminLayout children={<AdminDashbord />} />}
							/>
						}
					/>
					<Route
						path='/admin/users'
						element={
							<ProtectedRoute element={<AdminLayout children={<Users />} />} />
						}
					/>
					<Route
						path='/admin/catalog'
						element={
							<ProtectedRoute
								element={<AdminLayout children={<Catalog />} />}
							/>
						}
					/>
					<Route
						path='/admin/profile'
						element={
							<ProtectedRoute
								element={
									<AdminLayout children={<Profile profileType='admin' />} />
								}
							/>
						}
					/>
					<Route
						path='/admin/create-post'
						element={
							<ProtectedRoute
								element={<AdminLayout children={<CreatePost />} />}
							/>
						}
					/>
					<Route
						path='/admin/blog'
						element={
							<ProtectedRoute
								element={<AdminLayout children={<Blog view={view} />} />}
							/>
						}
					/>
					/>
					<Route
						path='/admin/blog/:topic'
						element={
							<ProtectedRoute
								element={<AdminLayout children={<Blog view={view} />} />}
							/>
						}
					/>
					<Route
						path='/admin/blog/:topic/:subtopic/posts'
						element={
							<ProtectedRoute
								element={<AdminLayout children={<Blog view={view} />} />}
							/>
						}
					/>
					<Route
						path='/admin/blog/post/:id'
						element={
							<ProtectedRoute element={<AdminLayout children={<Post />} />} />
						}
					/>
					<Route
						path='/member/home'
						element={
							<ProtectedRoute
								element={<MemberLayout children={<MemberHome />} />}
							/>
						}
					/>
					<Route
						path='/member/profile'
						element={
							<ProtectedRoute
								element={
									<MemberLayout children={<Profile profileType='member' />} />
								}
							/>
						}
					/>
					<Route path='/member/preferences/mid/:id' element={<Preferences />} />
					<Route
						path='/featured/*'
						element={
							<ProtectedRoute element={<MemberLayout children={<Watch />} />} />
						}
					/>
					<Route path='/businesscard' element={<BusinessCard />} />
					<Route
						path='/blog'
						element={<NonUserLayout children={<Blog view={view} />} />}
					/>
					<Route
						path='/blog/:topic'
						element={<NonUserLayout children={<Blog view={view} />} />}
					/>
					<Route
						path='/blog/:topic/:subtopic/posts'
						element={<NonUserLayout children={<Blog view={view} />} />}
					/>
					<Route
						path='/blog/post/:id'
						element={<NonUserLayout children={<Post />} />}
					/>
					{/* <Route
					path={'/sandbox/mid/:id'}
					element={<MemberLayout children={<Sandbox />} />}
				/> */}
				</Routes>
			</Router>
		</div>
	);
}

export default App;
