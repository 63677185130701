import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobile } from '../../redux/slices/appSlice';
import { getTopics } from '../../redux/slices/topicSlice';
import { breakpoints } from '../../util/data';
import Header from './components/Header';
import SideNav from './components/SideNav';
import Main from './components/Main';
import './non-user.scss';

const NonUserLayout = ({ children }) => {
	const dispatch = useDispatch();
	const { mainTopics } = useSelector((state) => state.topic);

	const handleMobile = useCallback(() => {
		const handleResize = () => {
			dispatch(setIsMobile(window.innerWidth <= breakpoints.md ? true : false));
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [dispatch]);

	const loadTopics = useCallback(() => {
		!mainTopics && dispatch(getTopics());
	}, [mainTopics, dispatch]);

	const boxStyles = {
		minHeight: 1,
		display: 'flex',
		flexDirection: { xs: 'column', lg: 'row' },
	};

	useEffect(() => {
		handleMobile();
	}, [handleMobile]);

	useEffect(() => {
		loadTopics();
	}, [loadTopics]);

	return (
		<>
			<Header />
			<Box sx={boxStyles}>
				<SideNav />
				<Main>{children}</Main>
			</Box>
		</>
	);
};

export default NonUserLayout;
