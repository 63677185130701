import {
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	openDelete,
	setDeleteData,
	setPhotoOpen,
} from '../../redux/slices/appSlice';
import { setIsCreateTopic } from '../../redux/slices/topicSlice';
import { setIsCreateTag } from '../../redux/slices/tagSlice';
import {
	getAvailableTopics,
	getAvailableSubs,
	setAvailableTopics,
	setAvailableSubs,
	populatePostData,
	clearPostData,
	setTopic,
	setSubtopic,
	setTitle,
	setContent,
	setShowCarousel,
	setCarouselSpeed,
	setVideoAddress,
	managePostMedia,
	updatePost,
	deletePost,
	clearSuccess,
} from '../../redux/slices/postSlice';
import { compareArray } from '../../util/helpers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './post.scss';
import Section from '../../components/Section';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';
import TransferList from '../../components/TransferList';
import Carousel from '../../components/Carousel';
import CreateDialog from '../../components/CreateDialog';
import Switch from '../../components/Switch';

const Post = () => {
	const { isMobile } = useSelector((state) => state.app);
	const { activeUser } = useSelector((state) => state.user);
	const { isCreateTopic } = useSelector((state) => state.topic);
	const { isCreateTag } = useSelector((state) => state.tag);
	const {
		selectedPost,
		availableTopics,
		availableSubtopics,
		topic,
		subtopic,
		title,
		tags,
		content,
		showCarousel,
		carouselSpeed,
		mediaPreview,
		isCover,
		videoAddress,
		success,
	} = useSelector((state) => state.post);
	const [updateMode, setUpdateMode] = useState(false);
	const [createType, setCreateType] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isMyPost = activeUser?._id === selectedPost?.author?._id ? true : false;
	const defaultTopicIndex = availableTopics?.findIndex(
		(option) => option.topic === topic
	);
	const defaultSubIndex = availableSubtopics?.findIndex(
		(option) => option.topic === subtopic
	);

	const handleToggleUpdateMode = () => {
		dispatch(updateMode ? clearPostData() : populatePostData(selectedPost));
		dispatch(updateMode ? setAvailableTopics(null) : getAvailableTopics());
		updateMode && dispatch(setAvailableSubs(null));
		setUpdateMode(!updateMode);
	};

	const handleDeleteClick = (type, url) => {
		let data;

		if (type === 'post') {
			data = {
				type,
				action: deletePost(selectedPost?._id),
			};
		} else if (type === 'image') {
			const actionData = new FormData();
			actionData.append('postId', selectedPost?._id);
			actionData.append('url', url);

			data = {
				type,
				action: managePostMedia(actionData),
			};
		}

		dispatch(setDeleteData(data));
		dispatch(openDelete(true));
	};

	const handleChange = (input, value) => {
		switch (input) {
			case 'createTopic':
				dispatch(setIsCreateTopic(value));
				setCreateType('Topic');
				break;
			case 'topic':
				dispatch(setTopic(value === '-1' ? '' : availableTopics[value]?.topic));
				value === '-1'
					? dispatch(setAvailableSubs(null))
					: dispatch(getAvailableSubs(availableTopics[value]?._id));
				break;
			case 'subtopic':
				dispatch(
					setSubtopic(value === '-1' ? '' : availableSubtopics[value]?.topic)
				);
				break;
			case 'title':
				dispatch(setTitle(value));
				break;
			case 'createCat':
				dispatch(setIsCreateTag(value));
				setCreateType('Tag');
				break;
			case 'video':
				dispatch(setVideoAddress(value));
				break;
			case 'show':
				dispatch(setShowCarousel(value));
				break;
			case 'speed':
				dispatch(setCarouselSpeed(value));
				break;
			case 'content':
				dispatch(setContent(value));
				break;
			default:
				break;
		}
	};

	const handleAddMedia = () => {
		dispatch(setPhotoOpen(true));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			id: selectedPost?._id,
			...(topic !== selectedPost?.topic && { topic }),
			...(subtopic !== selectedPost?.subtopic && { subtopic }),
			...(title !== selectedPost?.title && { title }),
			...(!compareArray(tags, selectedPost?.tags) && {
				tags,
			}),
			...(content !== selectedPost?.content && { content }),
			...(videoAddress !== selectedPost?.videoAddress && { videoAddress }),
			...(showCarousel !== selectedPost?.showCarousel && { showCarousel }),
			...(carouselSpeed !== selectedPost?.carouselSpeed && { carouselSpeed }),
		};

		dispatch(updatePost(data));
	};

	const handleLoadSubs = useCallback(() => {
		const topicId =
			availableTopics?.find((item) => item.topic === topic)?._id || null;
		topicId && dispatch(getAvailableSubs(topicId));
	}, [availableTopics, topic, dispatch]);

	const handleMediaUpload = useCallback(() => {
		if (mediaPreview) {
			const data = new FormData();
			data.append('postId', selectedPost?._id);
			data.append('b64str', mediaPreview);
			data.append('isCover', isCover);

			dispatch(managePostMedia(data));
		}
	}, [dispatch, selectedPost, mediaPreview, isCover]);

	const handleSuccess = useCallback(() => {
		if (success) {
			if (success === 'Post deleted successfully!') {
				navigate('/admin/blog');
			} else if (success === 'Post updated successfully!') {
				setUpdateMode(false);
			}

			setTimeout(() => {
				dispatch(clearSuccess());
			}, 2000);
		}
	}, [success, navigate, dispatch]);

	useEffect(() => {
		handleLoadSubs();
	}, [handleLoadSubs]);

	useEffect(() => {
		handleMediaUpload();
	}, [handleMediaUpload]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<Section id='post' maxWidth='lg'>
			<Paper className='wrapper' elevation={10}>
				{isMyPost && (
					<div className='post-actions'>
						{!updateMode && (
							<IconButton onClick={handleToggleUpdateMode}>
								<EditIcon htmlColor='steelblue' />
							</IconButton>
						)}
						<IconButton onClick={() => handleDeleteClick('post')}>
							<DeleteIcon className='delete-icon' />
						</IconButton>
					</div>
				)}
				{updateMode ? (
					<>
						<h2 style={{ textAlign: 'center' }}>Update Post</h2>
						<form id='update-form'>
							<Divider>
								<Chip label='Topic' size='small' className='divider=chip' />
							</Divider>
							<div className='update-form-group topic'>
								<Stack
									width={isMobile ? undefined : '350px'}
									direction={isMobile ? 'column' : 'row'}
									justifyContent='center'
									alignItems='center'
								>
									<Select
										defaultValue={defaultTopicIndex}
										onChange={(e) => handleChange('topic', e.target.value)}
									>
										<option value='-1'>Topic</option>
										{availableTopics?.map((item, index) => (
											<option key={item._id} value={index}>
												{item.topic}
											</option>
										))}
									</Select>
									{availableSubtopics && (
										<Select
											defaultValue={defaultSubIndex}
											onChange={(e) => handleChange('subtopic', e.target.value)}
										>
											<option value='-1'>Subtopic</option>
											{availableSubtopics?.map((item, index) => (
												<option key={item._id} value={index}>
													{item.topic}
												</option>
											))}
										</Select>
									)}
								</Stack>
								<FormControlLabel
									control={
										<Checkbox
											checked={isCreateTopic}
											onChange={(e) =>
												handleChange('createTopic', e.target.checked)
											}
										/>
									}
									label='New Topic'
								/>
							</div>
							<Divider>
								<Chip label='Title' size='small' className='divider-chip' />
							</Divider>
							<div className='update-form-group title'>
								<FormControl>
									<TextInput
										placeholder='Title'
										value={title}
										onChange={(e) => handleChange('title', e.target.value)}
									/>
								</FormControl>
							</div>
							<Divider>
								<Chip label='Tags' size='small' className='divider-chip' />
							</Divider>
							<div className='update-form-group transfer'>
								<FormControl>
									<TransferList isUpdate />
								</FormControl>
								<FormControlLabel
									control={
										<Checkbox
											checked={isCreateTag}
											onChange={(e) =>
												handleChange('createCat', e.target.checked)
											}
										/>
									}
									label='New Tag'
								/>
							</div>
							<Divider>
								<Chip label='Video' size='small' className='divider-chip' />
							</Divider>
							{videoAddress && (
								<div className='watch-container'>
									<div className='post-video-container'>
										<iframe
											title='Vlog Post'
											src={videoAddress}
											frameBorder='0'
											controls='0'
											allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
											allowFullScreen
										/>
									</div>
								</div>
							)}
							<div className='update-form-group video-url'>
								<FormControl>
									<TextInput
										placeholder='Video URL'
										value={videoAddress}
										onChange={(e) => handleChange('video', e.target.value)}
									/>
								</FormControl>
							</div>
							<Divider>
								<Chip label='Images' size='small' className='divider-chip' />
							</Divider>
							<div className='update-form-group images'>
								<div className='cover-photo-container'>
									<div className='cover-photo'>
										<div className='badge'>C</div>
										<img src={selectedPost?.coverPhoto} alt='cover' />
									</div>
								</div>
								{selectedPost?.images?.length > 0 && (
									<div className='image-container'>
										{selectedPost?.images?.map((image, i) => (
											<div className='post-img' key={i}>
												<IconButton
													className='del-photo-btn'
													onClick={() => handleDeleteClick('image', image)}
												>
													<DeleteIcon
														className='delete-icon'
														fontSize='small'
													/>
												</IconButton>
												<img src={image} alt='' />
											</div>
										))}
									</div>
								)}
								<Button onClick={handleAddMedia}>Add Photo</Button>
							</div>
							{selectedPost?.images?.length > 1 && (
								<>
									<Divider>
										<Chip
											label='Carousel'
											size='small'
											className='divider-chip'
										/>
									</Divider>
									<div className='update-form-group show'>
										<Stack direction='row' alignItems='center'>
											<Typography>Off</Typography>
											<Switch
												checked={showCarousel}
												onChange={(e) => handleChange('show', e.target.checked)}
											/>
											<Typography>On</Typography>
										</Stack>
										{showCarousel && (
											<>
												<FormControl>
													<TextInput
														type='number'
														value={carouselSpeed}
														onChange={(e) =>
															handleChange('speed', e.target.value)
														}
													/>
												</FormControl>
												<div className='carousel-preview'>
													<Carousel
														images={selectedPost?.images}
														speed={carouselSpeed}
													/>
												</div>
											</>
										)}
									</div>
								</>
							)}
							<Divider>
								<Chip label='Content' size='small' className='divider-chip' />
							</Divider>
							<div className='update-form-group'>
								<FormControl fullWidth>
									<textarea
										cols='50'
										rows='10'
										placeholder='Tell your story...'
										className='update-input update-content'
										value={content}
										onChange={(e) => handleChange('content', e.target.value)}
									/>
								</FormControl>
							</div>
							<div className='update-actions'>
								<button
									className='post-undo-btn'
									onClick={handleToggleUpdateMode}
								>
									Cancel
								</button>
								<button className='post-update-btn' onClick={handleSubmit}>
									Update
								</button>
							</div>
						</form>
					</>
				) : (
					<>
						<div className='post-vitals'>
							<div className='title-tags'>
								<h2>{selectedPost?.title}</h2>
								<Stack direction='row' gap={0.5}>
									{selectedPost?.tags.map((tag) => (
										<h5 key={tag._id}>{tag.tag}</h5>
									))}
								</Stack>
							</div>
							<div className='date-author'>
								<span className='post-date'>
									{new Date(selectedPost?.createdAt).toDateString()}
								</span>
								<span className='post-author'>
									{`Author: ${selectedPost?.author?.firstName} ${selectedPost?.author?.lastName}`}
								</span>
							</div>
						</div>
						{selectedPost?.videoAddress && (
							<div className='watch-container'>
								<div className='post-video-container'>
									<iframe
										title='Vlog Post'
										src={selectedPost?.videoAddress}
										frameBorder='0'
										controls='0'
										allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
									/>
								</div>
							</div>
						)}
						{selectedPost?.images?.length > 0 && !selectedPost?.showCarousel ? (
							<div className='image-container'>
								{selectedPost?.images?.map((image, i) => (
									<a key={i} href={image} target='_blank' rel='noreferrer'>
										<div className='post-img'>
											<img src={image} alt='' />
										</div>
									</a>
								))}
							</div>
						) : (
							selectedPost?.images?.length > 1 &&
							selectedPost?.showCarousel && (
								<div className='carousel-container'>
									<Carousel
										images={selectedPost?.images}
										speed={selectedPost?.carouselSpeed}
									/>
								</div>
							)
						)}
						<div className='post-content-container'>
							<pre className='post-content'>{selectedPost?.content}</pre>
						</div>
					</>
				)}
			</Paper>
			<CreateDialog open={isCreateTopic || isCreateTag} type={createType} />
		</Section>
	);
};

export default Post;
