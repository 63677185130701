import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
// import { PURGE } from 'redux-persist';

export const appAdapter = createEntityAdapter();
const initialState = appAdapter.getInitialState({
	loading: false,
	isMobile: false,
	drawerOpen: false,
	deleteDialog: false,
	deleteData: null,
	photoOpen: false,
	contentDialog: false,
	topicMenuOpen: false,
	errors: null,
});

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setIsMobile: (state, action) => {
			state.isMobile = action.payload;
		},
		setDrawerOpen: (state, action) => {
			state.drawerOpen = action.payload;
		},
		openDelete: (state, action) => {
			state.deleteDialog = action.payload;
		},
		setDeleteData: (state, action) => {
			state.deleteData = action.payload;
		},
		setPhotoOpen: (state, action) => {
			state.photoOpen = action.payload;
		},
		setContentDialog: (state, action) => {
			state.contentDialog = action.payload;
		},
		setTopicMenuOpen: (state, action) => {
			state.topicMenuOpen = action.payload;
		},
	},
});

export const {
	setIsMobile,
	setDrawerOpen,
	openDelete,
	setDeleteData,
	setPhotoOpen,
	setContentDialog,
	setTopicMenuOpen,
} = appSlice.actions;

export default appSlice.reducer;
