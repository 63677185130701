import './select.scss';

const Select = ({
	containerStyle,
	label,
	selectStyle,
	leftIcon,
	rightIcon,
	disabled,
	defaultValue,
	value,
	children,
	onChange,
	onFocus,
	error,
}) => {
	return (
		<div className='select-input' style={containerStyle}>
			{label && <h6>{label}</h6>}
			<div className='select-container' style={selectStyle}>
				{leftIcon}
				<select
					disabled={disabled}
					defaultValue={defaultValue}
					value={value}
					onChange={onChange}
					onFocus={onFocus}
				>
					{children}
				</select>
				{rightIcon}
			</div>
			{error && <h6 className='error'>{error}</h6>}
		</div>
	);
};

export default Select;
