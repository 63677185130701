import { Button, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import './blog.scss';
import Section from '../../components/Section';
import TopicItem from './components/TopicItem';
import BlogItem from './components/BlogItem';

const Blog = ({ view }) => {
	const { activeUser } = useSelector((state) => state.user);
	const { allPosts } = useSelector((state) => state.post);
	const { mainTopics, subtopics } = useSelector((state) => state.topic);
	const navigate = useNavigate();

	const handleCreateNewClick = () => navigate('/admin/create-post');

	const renderContent = () => {
		switch (view) {
			case 'topics':
				return mainTopics?.map((item) => (
					<TopicItem key={item._id} data={item} />
				));
			case 'subtopics':
				return subtopics?.map((item) => (
					<TopicItem key={item._id} data={item} />
				));
			case 'posts':
				return allPosts?.map((item) => <BlogItem key={item._id} data={item} />);
			default:
				return null;
		}
	};

	return (
		<Section id='blog' maxWidth='xl'>
			{activeUser && (
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					mb={2}
				>
					<Typography variant='h5'>Blogs</Typography>
					<Button
						id='create-new-btn'
						variant='contained'
						color='inherit'
						startIcon={<AddIcon />}
						onClick={handleCreateNewClick}
					>
						Create New
					</Button>
				</Stack>
			)}
			<div className='main'>{renderContent()}</div>
		</Section>
	);
};

export default Blog;
