import { Box, Divider, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	setMainTopics,
	setSubtopics,
} from '../../../../../../redux/slices/topicSlice';
import './content.scss';
import NavItem from '../NavItem';

const NavContent = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { mainTopics } = useSelector((state) => state.topic);

	const handleClick = () => {
		navigate('/');
		dispatch(setMainTopics(null));
		dispatch(setSubtopics(null));
	};

	const listStyles = {
		my: 3,
		mx: 2.5,
		py: 2,
		px: 2.5,
		display: 'flex',
		borderRadius: 1.5,
		alignItems: 'center',
		bgcolor: 'rgba(171, 171, 171, .12)',
	};

	return (
		<div id='nav-content'>
			<div id='logo-container' onClick={handleClick}>
				<img src='/head-logo.png' alt='logo' />
			</div>
			<Divider />
			<Box sx={listStyles}>
				<Box sx={{ ml: 2 }}>
					<Typography variant='subtitle2'>Blog</Typography>
				</Box>
			</Box>
			<Stack component='nav' spacing={0.5} sx={{ px: 2 }}>
				{mainTopics?.map((item) => (
					<NavItem key={item._id} data={item} />
				))}
			</Stack>
		</div>
	);
};

export default NavContent;
