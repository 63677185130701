import { Box, Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerOpen } from '../../../../redux/slices/appSlice';
import { NAV } from '../../../../util/data';
import NavContent from './components/NavContent';

const SideNav = () => {
	const { isMobile, drawerOpen } = useSelector((state) => state.app);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(setDrawerOpen(false));
	};

	const boxStyles = {
		flexShrink: { lg: 0 },
		width: { lg: NAV.WIDTH },
	};

	const desktopStyles = {
		height: 1,
		position: 'fixed',
		width: NAV.WIDTH,
		borderRight: '1px solid rgba(171, 171, 171, .2)',
	};

	return (
		<Box sx={boxStyles}>
			{isMobile ? (
				<Drawer
					open={drawerOpen}
					onClose={handleClose}
					slotProps={{ paper: { sx: { width: NAV.WIDTH } } }}
				>
					<NavContent />
				</Drawer>
			) : (
				<Box sx={desktopStyles}>
					<NavContent />
				</Box>
			)}
		</Box>
	);
};

export default SideNav;
