import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
import { logout } from './userSlice';
import atsApi from '../../api/atsApi';

export const createTag = createAsyncThunk(
	'tag/add_new',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.post('/tags', data);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getAllTags = createAsyncThunk(
	'tag/get_all',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get('/tags');
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteTag = createAsyncThunk(
	'tag/delete_tag',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.delete(`/tags/${data}`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const tagAdapter = createEntityAdapter();
const initialState = tagAdapter.getInitialState({
	loading: false,
	isCreateTag: false,
	newTag: '',
	allTags: [],
	success: null,
	errors: null,
});

export const tagSlice = createSlice({
	name: 'tag',
	initialState,
	reducers: {
		setIsCreateTag: (state, action) => {
			state.isCreateTag = action.payload;
		},
		setNewTag: (state, action) => {
			state.newTag = action.payload;
		},
		setAllTags: (state, action) => {
			state.allTags = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createTag.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(createTag.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.allTags = action.payload.allTags;
				state.isCreateTag = false;
				state.newTag = '';
			})
			.addCase(createTag.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getAllTags.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getAllTags.fulfilled, (state, action) => {
				state.loading = false;
				state.allTags = action.payload;
			})
			.addCase(getAllTags.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(deleteTag.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(deleteTag.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.allTags = action.payload.allTags;
			})
			.addCase(deleteTag.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(logout, (state) => {
				state.loading = false;
				state.isCreateTag = false;
				state.newTag = '';
				state.allTags = [];
				state.success = null;
				state.errors = null;
				tagAdapter.removeAll(state);
			});
	},
});

export const { setIsCreateTag, setNewTag, setAllTags } = tagSlice.actions;

export default tagSlice.reducer;
