import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setTopicMenuOpen } from '../../../../../../redux/slices/appSlice';
import {
	getSubtopics,
	setSubtopics,
} from '../../../../../../redux/slices/topicSlice';
import {
	setView,
	getPostsBySubtopic,
	setAllPosts,
	setSelectedPost,
	clearPostData,
} from '../../../../../../redux/slices/postSlice';
import RouterLink from '../../../../../../components/RouterLink';

const NavItem = ({ data }) => {
	const { isMobile, topicMenuOpen } = useSelector((state) => state.app);
	const { subtopics } = useSelector((state) => state.topic);
	const { selectedPost } = useSelector((state) => state.post);
	const dispatch = useDispatch();

	const handleClick = (topicId) => {
		if (subtopics) {
			dispatch(setTopicMenuOpen(false));
			dispatch(setSubtopics(null));
			dispatch(setAllPosts(null));
			dispatch(setView('topics'));
		} else {
			dispatch(getSubtopics(topicId));
			dispatch(setTopicMenuOpen(true));
			dispatch(setView('subtopics'));
		}

		if (selectedPost) {
			dispatch(setSelectedPost(null));
			dispatch(clearPostData());
		}
	};

	const handleSubClick = (topic, subtopic) => {
		dispatch(getPostsBySubtopic({ topic, subtopic }));
		dispatch(setView('posts'));

		if (selectedPost) {
			dispatch(setSelectedPost(null));
			dispatch(clearPostData());
		}
	};

	return (
		<>
			<ListItemButton
				component={RouterLink}
				href={subtopics ? `/blog` : `/blog/${data.topic}`}
				onClick={() => handleClick(data._id)}
			>
				<ListItemText primary={data.topic} />
			</ListItemButton>
			<Collapse in={topicMenuOpen} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					{subtopics?.map(
						(item) =>
							item.mainTopic._id === data._id && (
								<ListItemButton
									key={item._id}
									sx={{ pl: isMobile ? 5 : 8 }}
									component={RouterLink}
									href={`/blog/${item?.mainTopic?.topic}/${item.topic}/posts`}
									onClick={() =>
										handleSubClick(item.mainTopic.topic, item.topic)
									}
								>
									<ListItemText primary={item.topic} />
								</ListItemButton>
							)
					)}
				</List>
			</Collapse>
		</>
	);
};

export default NavItem;
