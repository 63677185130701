import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

export const breakpoints = {
	xs: 0,
	sm: 768,
	md: 1024,
	lg: 1266,
	xl: 1536,
};

export const HEADER = {
	H_MOBILE: 64,
	H_DESKTOP: 80,
	H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
	WIDTH: 280,
};

export const adminNav = [
	{
		title: 'dashboard',
		path: '/admin/dashboard',
		icon: <DashboardIcon />,
	},
	{
		title: 'users',
		path: '/admin/users',
		icon: <AccountBoxIcon />,
	},
	{
		title: 'catalog',
		path: '/admin/catalog',
		icon: <ShoppingCartIcon />,
	},
	{
		title: 'blog',
		path: '/admin/blog',
		icon: <HistoryEduIcon />,
	},
];

export const userNav = [
	{
		title: 'dashboard',
		path: '/user/dashboard',
		icon: <DashboardIcon />,
	},
	{
		title: 'profile',
		path: '/user/profile',
		icon: <AccountBoxIcon />,
	},
	// {
	// 	title: 'product',
	// 	path: '/user/products',
	// 	icon: <ShoppingCartIcon />,
	// },
];
