import { Card, CardContent, CardMedia, Stack } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateViews, clearSuccess } from '../../../../redux/slices/postSlice';
import './blog-item.scss';

const BlogItem = ({ data }) => {
	const { activeUser } = useSelector((state) => state.user);
	const { success } = useSelector((state) => state.post);
	const {
		_id,
		topic,
		subtopic,
		title,
		tags,
		content,
		coverPhoto,
		images,
		videoAddress,
		numViews,
		author,
		createdAt,
	} = data;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClick = () => {
		const updateData = {
			id: _id,
			topic,
			subtopic,
		};

		dispatch(updateViews(updateData));
	};

	const cardStyles = {
		width: 345,
		height: 378,
		borderRadius: 4,
	};

	const mediaStyles = {
		height: 195,
		borderRadius: 4,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		...(coverPhoto !==
			'https://ats-backend-hr1h.onrender.com/uploads/images/no-image.png' && {
			objectFit: 'fill',
		}),
		// objectFit: 'fill',
	};

	const handleSuccess = useCallback(() => {
		if (success && success === 'Views updated successfully!') {
			if (
				activeUser &&
				(activeUser?.role === 'admin' || activeUser?.role === 'superAdmin')
			) {
				navigate(`/admin/blog/post/${_id}`);
			} else if (!activeUser) {
				navigate(`/blog/post/${_id}`);
			}

			setTimeout(() => {
				dispatch(clearSuccess());
			}, 3000);
		}
	}, [_id, activeUser, success, dispatch, navigate]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<Card
			sx={cardStyles}
			className='pressable'
			elevation={10}
			onClick={handleClick}
		>
			<CardMedia
				sx={mediaStyles}
				component='img'
				src={coverPhoto}
				title='post media'
			/>
			<CardContent>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<div className='vitals'>
						<div className='title-tag'>
							<h3>{title}</h3>
							<Stack direction='row'>
								{tags?.map((tag) => (
									<h6 key={tag._id}>{tag.tag}</h6>
								))}
							</Stack>
						</div>
						<div className='date-author'>
							<span className='blog-date'>
								{new Date(createdAt).toDateString()}
							</span>
							<span className='blog-author'>{`Author: ${author?.firstName} ${author?.lastName}`}</span>
						</div>
					</div>
				</Stack>
			</CardContent>
			<div className='preview-container'>
				<div className='limited-txt'>
					<p>{content}</p>
				</div>
				<div className='attribute-container'>
					<h6>Views: {numViews}</h6>
					<Stack direction='row' alignItems='center' gap={1}>
						{videoAddress && <p>🎥</p>}
						{images?.length > 0 && <p>🖼️</p>}
						{content && <p>📝</p>}
					</Stack>
				</div>
			</div>
		</Card>
	);
};

export default BlogItem;
