import Slider from 'react-slick';
import './carousel.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ images, speed }) => {
	const settings = {
		infinite: true,
		speed,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<Slider {...settings} className='slide' autoplay>
			{images?.map((item, i) => (
				<a
					key={i}
					href={item}
					className='pic-btn'
					target='_blank'
					rel='noreferrer'
				>
					<div className='pic-wrapper'>
						<img src={item} alt='' />
					</div>
				</a>
			))}
		</Slider>
	);
};

export default Carousel;
