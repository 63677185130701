import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerOpen } from '../../../../redux/slices/appSlice';
import { NAV, HEADER } from '../../../../util/data';
import User from '../../../../components/User';

const Header = () => {
	const { isMobile } = useSelector((state) => state.app);
	const dispatch = useDispatch();

	const handleDrawer = () => {
		dispatch(setDrawerOpen(true));
	};

	return (
		<AppBar
			sx={{
				boxShadow: 'none',
				height: HEADER.H_MOBILE,
				zIndex: 1101,
				bgcolor: '#f7f7f7',
				...(!isMobile && {
					width: `calc(100% - ${NAV.WIDTH + 1}px)`,
					height: HEADER.H_DESKTOP,
				}),
			}}
		>
			<Toolbar sx={{ height: 1, px: { lg: 5 } }}>
				{isMobile && (
					<IconButton sx={{ mr: 1 }} onClick={handleDrawer}>
						<MenuUnfoldOutlined />
					</IconButton>
				)}
				<Box sx={{ flexGrow: 1 }} />
				<Stack direction='row' alignItems='center' spacing={1}>
					<User type='admin' />
				</Stack>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
